var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[_c('v-data-table',{ref:"table",staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.perPage,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100, 500] },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDirection,"page":_vm.page,"server-items-length":_vm.total,"loading":_vm.spinnerActive},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDirection=$event},"update:sort-desc":function($event){_vm.sortDirection=$event},"update:page":function($event){_vm.page=$event},"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":_vm.getItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("AuditLogs")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Old state")]),_c('table',{staticStyle:{"width":"49%"}},_vm._l((_vm.format(_vm.editedItem.old_state)),function(item,key){return _c('tr',{key:'old_'+item,class:{ 'mark-updated': _vm.format(_vm.editedItem.old_state)
                                                    && _vm.format(_vm.editedItem.new_state)
                                                    && _vm.format(_vm.editedItem.old_state)[key] !== _vm.format(_vm.editedItem.new_state)[key]
                                                    && key !== 'updated_at'
                          }},[_c('td',{staticClass:"pr-4 pt-1"},[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(item))])])}),0)]),_c('v-col',[_c('p',[_vm._v("New state")]),_c('table',{staticStyle:{"width":"49%"}},_vm._l((_vm.format(_vm.editedItem.new_state)),function(item,key){return _c('tr',{key:'new_'+item,class:{ 'mark-updated': _vm.format(_vm.editedItem.old_state)
                                                    && _vm.format(_vm.editedItem.new_state)
                                                    && _vm.format(_vm.editedItem.old_state)[key] !== _vm.format(_vm.editedItem.new_state)[key]
                                                    && key !== 'updated_at'
                          }},[_c('td',{staticClass:"pr-4 pt-1"},[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(item))])])}),0)])],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getItems}},[_vm._v("No data")])]},proxy:true},{key:"item.created_at",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD MMM YYYY - hh:mm:ss'))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }