<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, 500] }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>AuditLogs</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <p>Old state</p>
                        <table style="width: 49%">
                          <tr
                            v-for="(item, key) in format(editedItem.old_state)"
                            :key="'old_'+item"
                            :class="{ 'mark-updated': format(editedItem.old_state)
                                                      && format(editedItem.new_state)
                                                      && format(editedItem.old_state)[key] !== format(editedItem.new_state)[key]
                                                      && key !== 'updated_at'
                            }"
                          >
                            <td class="pr-4 pt-1">{{key}}</td>
                            <td>{{item}}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col>
                        <p>New state</p>
                        <table style="width: 49%">
                          <tr
                            v-for="(item, key) in format(editedItem.new_state)"
                            :key="'new_'+item"
                            :class="{ 'mark-updated': format(editedItem.old_state)
                                                      && format(editedItem.new_state)
                                                      && format(editedItem.old_state)[key] !== format(editedItem.new_state)[key]
                                                      && key !== 'updated_at'
                            }"
                          >
                            <td class="pr-4 pt-1">{{key}}</td>
                            <td>{{item}}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{$moment(item.created_at).format('DD MMM YYYY - hh:mm:ss')}}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { fetchAuditLogs } from "@/services"
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "AuditLogs",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "created_at",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Name", value: "first_name" },
        { text: "Surame", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Action", value: "action" },
        { text: "Taxonomy", value: "taxonomy" },
        { text: "Timestamp", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fileName: null,
      imageUpdated: false,
      collaborationImage: null,
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item"
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    format(item) {
      if (item) {
        return JSON.parse(item)
      } else {
        return null
      }
    },
    async getItems() {
      if (this.spinnerActive) return
      this.items = []
      setTimeout(async () => {
        let tableData = {}
        tableData["perPage"] = this.perPage
        tableData["sortBy"] = this.sortBy
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC"
        tableData["page"] = this.page
        this.spinnerActive = true
        await fetchAuditLogs(tableData)
          .then((res) => {
            if (res.data) {
              this.items = res.data.data.data
              this.spinnerActive = false
              this.total = res.data.data.total
            }
          })
          .catch((error) => {
            console.log(error)
            this.spinnerActive = false
          })
      }, 0)
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = this.defaultItem
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.collaborationImage = item.image
      this.editedItem.image = null
      this.dialog = true
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
  },
}
</script>

<style scoped>
.mark-updated{
  background-color: #e241417a;
}
</style>